<template lang="html">
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md3>
        <v-form v-model="valid" @keyup.native.enter="onLogin">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>ĐĂNG NHẬP</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon large>mdi-login</v-icon>
            </v-toolbar>
            <v-card-text>
                <v-text-field prepend-icon="mdi-email" label="Email" type="text" v-model="login.email" :rules="[required('Email'),emailFormat()]" />
                <v-text-field prepend-icon="mdi-lock" label="Password" type="password" v-model="login.password" :rules="[required('Mật khẩu')]" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :loading="loading" :disabled="btnDisable" @click="onLogin" dark>{{loginText}}</v-btn>
            </v-card-actions>
          </v-card>
      </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {auth} from '@/plugins/auth'
import validations from '@/plugins/helpers/validations'
export default {
  data () {
    return {
      valid:false,
      loading: false,
      login:{
        login:'',
        password: '',
      }
    }
  },
  computed:{
    btnDisable(){
      let status = false
      if(this.loading === true) status = true
      if(status !== true) status = !this.valid
      return status
    },
    loginText(){
      return this.loading ? 'Đang xử lí...':'Đăng nhập'
    }
  },
  methods: {
    ...validations,
    onLogin(){
      this.loading = true
      auth.signInWithEmailAndPassword(this.login.email, this.login.password)
      .then(data=>{
        const {user} = data
        if(user){
          this.$toast.success("Đã đăng nhập thành công !!!")
          this.$store.dispatch("DoLogged")
        }else{
          this.$toast.error('Sai tài khoản hoặc mật khẩu !!!')
        }
      })
      .catch(e=>{
        const {code} = e
        if(code === 'auth/wrong-password'){
          this.$toast.error('Sai tài khoản hoặc mật khẩu !!!')
          return
        }
        this.$toast.error(e.message)
      })
      .finally(()=>{
        this.loading = false
      })
    }
  },
  mounted(){
  }
}
</script>

<style>

</style>